<template>
  <div>
    <div class="is-flex">
      <h1 class="title is-3">Hírek</h1>
<!--      <b-button class="ml-auto"-->
<!--                type="is-primary">-->
<!--        Összes hír olvasottnak jelölése-->
<!--      </b-button>-->
    </div>
    <div class="has-background-warning mt-4 p-4">
      <h2 class="title is-4">
        Jelenleg nincs aktív hír az oldalon
      </h2>
    </div>
    <!--    <section>-->
    <!--      <div class="box has-background-warning">-->
    <!--        <div class="is-flex">-->
    <!--          <h2 class="title is-6">Cím (olvasatlan)</h2>-->
    <!--          <b-button class="ml-auto"-->
    <!--                    type="is-light">Olvasottnak jelöl-->
    <!--          </b-button>-->
    <!--        </div>-->
    <!--        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore-->
    <!--          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea-->
    <!--          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu-->
    <!--          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt-->
    <!--          mollit anim id est laborum.</p>-->
    <!--      </div>-->
    <!--      <div class="box">-->
    <!--        <h2 class="title is-6">Cím</h2>-->
    <!--        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore-->
    <!--          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea-->
    <!--          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu-->
    <!--          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt-->
    <!--          mollit anim id est laborum.</p>-->
    <!--      </div>-->
    <!--      <div class="box">-->
    <!--        <h2 class="title is-6">Cím</h2>-->
    <!--        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore-->
    <!--          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea-->
    <!--          commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu-->
    <!--          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt-->
    <!--          mollit anim id est laborum.</p>-->
    <!--      </div>-->
    <!--    </section>-->
  </div>
</template>

<script>

export default {
  name: 'News',
  components: {},
  methods: {},
}
</script>
